export enum PageId {
  uncovered = "uncovered",
  quoting = "quoting",
  quotes = "quotes",
  hasChip = "hasChip",
  noChip = "noChip",
  petParent = "petParent",
  address = "address",
  access = "access",
  chip = "chip",
  petFamily = "petFamily",
  details = "details",
  binding = "binding",
  welcome = "welcome",
  smallPrint = "smallPrint",
  preExistencesConditions = "preExistencesConditions",
  waitingPeriod = "waitingPeriod",
  landing = "landing",
  checkout = "checkout",
  cardholderName = "cardholderName",
  cardholderAddress = "cardholderAddress",
}
